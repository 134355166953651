<template>
<div class='notifications'>
	<div class='blockButton bell' @click='contextMenu=true' :class='{ unread }'>
		<svg class='smallIcon'>
			<use xlink:href='#bell' />
		</svg>
	</div>
	<ContextMenu
		v-if='contextMenu'
		:pos='{ el: $el, align: "center" }'
		:persist='true'
		@close='contextMenu=false'
	>
		<div class='menu' ref='menu'>
			<Scroll :fetchNext='fetchNext'>
				<div class='list'>
					<div v-if='unread' class='markRead' @click.stop='markRead'>Mark as Read</div>

					<template v-for='notification in notifications'>
						
						<div v-if='notification.type==="heading"' class='heading' :key='notification.label'>{{notification.label}}</div>
						
						<div 
							v-else-if='notification.type==="clientFeedbackProvided"' 
							class='alert feedback' 
							:key='notification.id' 
							:class='{ unread: !notification.viewed, highlight: notification.score<=1 }'
							@click='goToFeedback(notification)'
						>
							<div>
								<img :src="`/img/faces/${notification.score}.png`" />
							</div>
							<div>
								<div class='summary'><b>{{notification.firstname}} {{notification.lastname}}</b> provided feedback.</div>
								<div v-if='notification.comments' class='detail'>{{notification.comments}}</div>
							</div>
						</div>
						
						<div 
							v-else-if='notification.type==="clientOutcomeCompleted"' 
							class='alert outcome' 
							:key='notification.id' 
							:class='{ unread: !notification.viewed }'
							@click='goToOutcome(notification)'
						>
							<div>
								<svg class='smallIcon'>
									<use xlink:href='#outcomes' />
								</svg>
							</div>
							<div>
								<div class='summary'><b>{{notification.firstname}} {{notification.lastname}}</b> completed an outcome.</div>
								<div class='detail'>{{notification.customTitle || notification.outcomeType}}</div>
							</div>
						</div>

					</template>
				</div>
			</Scroll>
			<transition name='fade'><Loading v-show='working' /></transition>
		</div>
	</ContextMenu>
</div>
</template>

<script>
/*
import { smallModalMixin } from '@/components/common/mixins/modal'	
import ConfirmModal from '@/components/common/modals/Confirm'	
import Folders from './Folders'
import List from './List'
import Item from './Item'
*/
import Cookies from 'js-cookie'
//import { largeModalMixin } from '@/components/common/mixins/modal'
import Scroll from '@/components/common/Scroll'	
import ContextMenu from '@/components/common/ContextMenu'	
import Loading from '@/components/common/Loading'
//import EditOutcome from '@/components/clinician/InspectClientOutcome'


export default {
	name: 'Notifications',
	components: { ContextMenu, Scroll, Loading },
//	mixins: [largeModalMixin],
	data() { return {
		working: false,
		contextMenu: false,
		timer: null
	}},
	computed: {
		notifications() {
			const list = this.$store.state.notifications.list.map(id=>this.$store.state.notifications.notifications[id])
			const oneDay = 24 * 60 * 60 * 1000
			const today = new Date().getTime() - oneDay
			const yesterday = new Date().getTime() - oneDay * 2
			const lastWeek = new Date().getTime() - oneDay * 7
			const todayIndex = list.findIndex(i=>new Date(i.created).getTime() > today)
			if (todayIndex!==-1) list.splice(todayIndex, 0, { type: 'heading', label: 'Today' })
			const yesterdayIndex = list.findIndex(i=>new Date(i.created).getTime() > yesterday && new Date(i.created).getTime() < today)
			if (yesterdayIndex!==-1) list.splice(yesterdayIndex, 0, { type: 'heading', label: 'Yesterday' })
			const lastWeekIndex = list.findIndex(i=>new Date(i.created).getTime() > lastWeek && new Date(i.created).getTime() < yesterday)
			if (lastWeekIndex!==-1) list.splice(lastWeekIndex, 0, { type: 'heading', label: 'Last 7 Days' })
			const earlierIndex = list.findIndex(i=>new Date(i.created).getTime() < lastWeek)
			if (earlierIndex!==-1) list.splice(earlierIndex, 0, { type: 'heading', label: 'Earlier' })				
			return list
		},
		unread() {
			//if (this.contextMenu) return false
			const list = this.$store.state.notifications.list.map(id=>this.$store.state.notifications.notifications[id])
			const oneDay = 24 * 60 * 60 * 1000			
			const lastWeek = new Date().getTime() - oneDay * 7
			const matches = list.filter(i=>new Date(i.created).getTime() > lastWeek) // older than this and we don't care
			return !!matches.find(i=>!i.viewed)
		}
	},
	methods: {
		fetchTop() {
			this.$store.commit('notifications/reset')
			this.fetchNext()
		},
		async fetchNext() {
			this.working = true
			await this.$store.dispatch('notifications/fetch')
			this.working = false
		},
		async goToFeedback({ clientId }) {
			await this.$store.dispatch('manageClients/setFeedback', true)
			Cookies.set(`${this.$store.state.profile.user.id}-clientFeedback`, true) // not good, this should go into the store in the new version
			await this.$store.dispatch("manageClients/setSetsView")
			this.$router.replace({ name: 'clients', params: { clientId }}).catch(()=>{})
		},
		async goToOutcome({ clientId }) {
			await this.$store.dispatch("manageClients/setOutcomesView")
			this.$router.replace({ name: 'clients', params: { clientId }}).catch(()=>{})			
			//await this.$store.dispatch('clients/parseMerge', { id: clientId, firstname, lastname, email }) // eesh
			//this.showLargeModal(EditOutcome, { id: outcomeId, sendNow: false }, '100%') 
		},
		markRead() {
			this.$store.dispatch('notifications/markRead')
		}
	},
	watch: {
		contextMenu(v) {
			if (v) {
				//this.fetchTop()
				this.$nextTick(()=>{
					if (this.$refs.menu) this.$refs.menu.style.height=`${window.innerHeight-150}px`
				})
			}
		}
	},
	mounted() {
		const longPoll = () => {
			if (!this.contextMenu) this.fetchTop()
			this.timer = setTimeout(longPoll, 1000 * 60 * 5)
		}
		longPoll()
	},
	beforeDestroy() {
		clearTimeout(this.timer)
	}
}
</script>

<style lang='scss'>
.notifications {
	&:after { display: none; }
	.contextMenuWrapper > div { padding: 0; }
	.bell {
		width: 32px;
		height: 32px;
		margin-top: 4px;
		padding: 0;
		position: relative;
		&.unread {
			background: rgba($color-success-accent, 0.2);
			color: $color-success;
			border-radius: 50%;
			animation: selectTimer 1000ms linear;	
		}
	}
	.menu { 
		width: 360px; 
		background: $color-neutral-panel;
		.markRead {
			height: 24px;
			padding: 0 $size-gutter * 2;
			border-radius: 12px;
			font-size: $size-font-standard;
			font-weight: bold;
			display: flex;
			align-items: center;
			color: #fff;
			background: $button-gradient-primary;
			position: absolute;
			top: 8px;
			right: 21px;
			z-index: 10;
			cursor: pointer;
		}
		.list {
			padding-bottom: 1px;
			.heading {
				height: $size-control-height;
				display: flex;
				align-items: center;
				padding-left: $size-gutter * 2;
				font-size: $size-font-small;
				color: $color-primary-accent;
				font-weight: bold;
				text-transform: uppercase;
				text-decoration: underline;
			}
			.alert {
				
				margin-bottom: 1px;
				font-size: $size-font-standard;
				line-height: $size-lineHeight-standard;
				height: 50px;
				display: flex;
				align-items: center;
				cursor: pointer;
				> div:first-child { display: flex; align-items: center; justify-content: center; width: 50px; }
				> div:last-child { flex: 1; min-width: 0; padding-right: $size-gutter * 2; }
				border-left: 7px solid transparent;

				&.unread {
					background: #fff;
					border-left: 7px solid $color-primary-accent;
				}
				&.highlight .summary { color: $color-alert; b { color: $color-alert; } }
			}
			.feedback img { width: 24px; }
			.outcome svg { color: $color-success;  }
			.summary {			
				b { color: $color-primary-accent; }
			}	
			.detail {
				font-style: italic;
				color: $color-neutral-dark;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}			
		}
	}
}
</style>