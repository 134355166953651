<template>
<div class='callToAction'>
	<a href='https://boarding.simpleset.app/#/register/pro/en'>
		<div class='closeButtonWrapper'>
			<CloseButton @click.stop='$emit("close")' />
		</div>

		<div class='panel'>
			<div class='content'><div>
				<header>Sign Up for SimpleSet!</header>
				<p>No Risk 14 Day Free Trial</p>
				<p>Immediate access to everything</p>
				<p>Cancel anytime</p>
				<div class='bigButton'><svg class='smallIcon'><use xlink:href='#angleRight' /></svg></div>
			</div></div>
			<div class='logoWrapper'><div><AnimatedLogo /></div></div>
		</div>
	</a>
</div>
</template>

<script>
import CloseButton from '@/components/common/buttons/Close'
import AnimatedLogo from '@/components/common/AnimatedLogo'

export default {
	name: 'CallToAction',
	components: { CloseButton, AnimatedLogo }
}
</script>

<style lang='scss'>
.callToAction {
	position: relative;
	height: 100%;

	.closeButtonWrapper {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 10;
	}

	.panel {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;		
		display: flex;

		.logoWrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			min-width: 50%;
			padding-bottom: 30px;
			background: $color-neutral-silver;	

			> div { 
				position: relative; 
				width: 200px; 
				height: 200px; 
				margin: 0 auto; 
				margin-bottom: $size-gutter * 2;
			}
		}

		.content { 
			text-align: center;
			min-width: 50%;
			display: flex;
			align-items: center;
			justify-content: center;	
			padding-bottom: 30px;

			header {
				font-weight: normal;
				font-size: 26px;
				color: $color-primary-accent; 
				margin-bottom: $size-gutter * 7;
				text-align: center;
			}

			p {
				font-size: 20px;
				margin: 0;
				line-height: 32px;
				color: $color-black !important;
			}

			.bigButton {
				color: $color-white;
				width: 100px;
				height: 100px;
				border-radius: 50%;
				background: $button-gradient-success;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: $size-gutter * 8;
				margin-left: auto;
				margin-right: auto;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;

				svg { width: 60%; height: 60%; }
			}
		}
	}

}
</style>
