<template>
<div class='clinician'>
	<div v-if='$store.state.profile.user.clinicianType === "demo"' class='leftPanel'>
		<DemoFunnel />
	</div>
	<!--
		<div v-else-if='survey' class='surveyPanel'>
		<div style="padding: 14px; ">
			<div class='iconTextButton alert' @click='closeSurvey'>
			<svg class='smallIcon'>
			<use xlink:href='#cancel' />
			</svg>
			<span class='label'>Close Survey</span>
			</div>
		</div><div style="padding-top: 14px;">
			<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdT6dpnNnc7JB4ApIjFv1-vi6JF_7EkogGm8ZfLEz5TE5ksag/viewform?embedded=true" width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
		</div>
		</div>
	-->
		<!--
		<div v-else class='topPanel'></div>
	-->
	<div class='stage'>
		<div class='navPanel'><div>
			<ClinicianNav />
		</div></div>
		<div class='mainPanel'>
			<router-view ref='routerView' /> 
		</div>
	</div>
	<Bus v-if='$store.state.bus.bussing' />	
	<MobileReqs v-if='$store.state.main.mobile' />
</div>
</template>

<script>
import { smallModalMixin } from '@/components/common/mixins/modal'
import ClinicianNav from '@/components/clinician/ClinicianNav'
import Bus from '@/components/common/bus/Bus'
import MobileReqs from '@/components/common/MobileReqs'
import DemoFunnel from '@/components/clinician/banners/demo/Demo'
import CallToAction from '@/components/clinician/banners/demo/CallToAction'
import Tips from '@/components/clinician/banners/tips/Tips'
//import Cookies from 'js-cookie'

export default {
	name: 'Clinician',
	mixins: [smallModalMixin],
	components: { ClinicianNav, Bus, DemoFunnel, MobileReqs },
/*
	data() { return {
		survey: !Cookies.getJSON('surveyCompleted'),
	}},
	methods: {
		closeSurvey() { 
			Cookies.set('surveyCompleted', true)
			this.survey = false 
		}
	},
*/
	mounted() {
		window.Beacon('init', 'e041befd-3cec-41ea-a041-7f0fba810899')
		if (this.$store.state.profile.user.clinicianType === 'demo') {
			this.showSmallModal(Tips, null, '950px', '630px') 
			setTimeout(() => {
				this.showSmallModal(CallToAction, null, '950px', '630px') 
			}, 90 * 1000)
		}
	},
	beforeRouteUpdate(to, from, next) {
		if (
			this.$refs.routerView && 	
			(
				(from.name === 'education' && to.name !== 'education') ||
				(from.name === 'outcomes' && to.name !== 'outcomes') 
			)
		) {
			this.$refs.routerView.checkSaved(next)
		} else {
			next()
		}
	}
}
</script>

<style lang='scss'>

.clinician {
	background: $color-neutral-panel;
	position: relative;
	@include fill;
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-rows: auto 1fr;
	grid-template-areas: 	'topPanel topPanel'
							'leftPanel stage';


	.stage {
		overflow: hidden;
		grid-area: stage;		
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto 1fr;
		position: relative;
		
		> .navPanel {
			background: linear-gradient(to top, $color-white, $color-neutral-silver);
			min-width: 0;
			> div {
				width: 100%;
				overflow: scroll;
			}
		}

		> .mainPanel {
			overflow-x: scroll;
			> div > div { border-top: 1px solid $color-neutral-shadow; }
			position: relative;
		}
	}

	> .leftPanel {
		grid-area: leftPanel;
		position: relative;
		border-right: 1px solid $color-neutral-shadow;
		background: #fff;
	}

	> .surveyPanel {
		position: absolute;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 400px;
		height: 100%;
		border-right: 1px solid $color-neutral-shadow;
		background: #fff;
		box-shadow: $box-shadow-lifted-on, 0 0 0 2000px rgba(0,0,0,.2);
		display: grid;
		grid-template-rows: auto 1fr;
	}

	> .topPanel { 
		grid-area: topPanel;
		position: relative;
	}
}
</style>