<template>
<div class='clinicianNav'>
	<AppNav>
		<template slot='leftNav'>		
			<template v-if='!onPhysioPedia'>
				<router-link tag='div' :to='{ name: "designer" }' class='appNavButton' @click.native='loading="designer"'>
					<svg class='smallIcon'>
						<use xlink:href='#designer' />
					</svg>
					<Loading v-if='loading==="designer"' />					
					<span class='label'>{{ $t('elements.nav.designer') }}</span>
				</router-link>
				<router-link tag='div' :to='{ name: "templates" }' class='appNavButton' @click.native='loading="templates"'>
					<svg class='smallIcon'>
						<use xlink:href='#templates' />
					</svg>
					<Loading v-if='loading==="templates"' />					
					<span class='label'>{{ $t('elements.nav.folders') }}</span>
				</router-link>						
				<router-link tag='div' :to='{ name: "education" }'  class='appNavButton' @click.native='loading="education"'>
					<svg class='smallIcon'>
						<use xlink:href='#education' />
					</svg>
					<Loading v-if='loading==="education"' />					
					<span class='label'>{{ $t('elements.nav.education') }}</span>
				</router-link>
				<router-link tag='div' :to='{ name: "outcomes" }' class='appNavButton' @click.native='loading="outcomes"'>
					<svg class='smallIcon'>
						<use xlink:href='#outcomes' />
					</svg>
					<Loading v-if='loading==="outcomes"' />
					<span class='label'>{{ $t('elements.nav.outcomes') }}</span>
				</router-link>												
				<router-link v-if='$store.state.profile.user.allowPHI' tag='div' :to='{ name: "clients" }'  class='appNavButton' @click.native='loading="clients"'>
					<svg class='smallIcon'>
						<use xlink:href='#clients' />
					</svg>
					<Loading v-if='loading==="clients"' />					
					<span class='label'>{{ $t('elements.nav.clients') }}</span>
				</router-link>					
			</template>
			<div class='physioPediaWrapper'>
				<input type='text' class='st-default-search-input' placeholder='Physiopedia...' @focus='onPhysioPedia=true' @blur='onPhysioPedia=false' @keydown='onPhysioPediaEnter' />	
			</div>		
		</template>
		<template slot='rightNav'>		
			<!--	
			<router-link tag='div' :to='{ name: "clinicianSettingsRefer" }'  class='referColleagues appNavButton' :class='{ shake }'>
				<span class='label'>$$ Earn Rewards!</span>
			</router-link>		
			-->
			<Notifications />
		</template>
	</AppNav>
	<Flash />
</div>
</template>

<script>
import Loading from '@/components/common/Loading'
import { largeModalMixin } from '@/components/common/mixins/modal'
import AppNav from '@/components/common/AppNav'
import Flash from '@/components/common/Flash'
import Notifications from '@/components/clinician/notifications/Notifications'

export default {
	name: 'ClinicianNav',
	mixins: [largeModalMixin],
	components: {
		AppNav,
		Loading,
		Flash,
		Notifications
	},
	data() { return {
		onPhysioPedia: false,
		observer: null,
		shake: false,
		loading: null
	}},
	computed: {
	},
	methods: {
		onPhysioPediaEnter(e) {
			if (e.which === 13) {
				e.stopImmediatePropagation()
				const link = document.querySelector('.st-default-autocomplete .st-keyboard-active-item')
				if (link) window.open(link.href, '_blank')
				return false		
			}			
		},
		createObserver() {
			const updateLinks = () => {
				this.observer.disconnect()
				const links = document.querySelectorAll('.st-default-autocomplete a')
				const clickLink = e => {
					e.stopImmediatePropagation()
					window.open(e.target.closest('a').href, '_blank')
					return false					
				}							
				links.forEach(link => {
					link.removeEventListener('mousedown', clickLink)
					link.addEventListener('mousedown', clickLink)				
				})
				initObserver()
			}
			const initObserver = () => {
				const targetNode = document.querySelector('.st-default-autocomplete')
				this.observer = new MutationObserver(updateLinks)
				this.observer.observe(targetNode, { attributes: true, subtree: true, childList: true })
			}
			initObserver()
		},
		destroyObserver() {
			this.observer.disconnect()
			this.observer = null
		}
	},
	mounted() {
		/*eslint-disable */
		(function(w,d,t,u,n,s,e){w['SwiftypeObject']=n;w[n]=w[n]||function(){
		(w[n].q=w[n].q||[]).push(arguments);};s=d.createElement(t);
		e=d.getElementsByTagName(t)[0];s.async=1;s.src=u;e.parentNode.insertBefore(s,e);
		})(window,document,'script','//s.swiftypecdn.com/install/v2/st.js','_st');
		_st('install','43ZVT-g2_ksiLpUX3zSj','2.0.0');
		/*eslint-enable */
		setTimeout(() => { this.shake = true }, 5000)
	},
	beforeDestroy() {
		const el = document.getElementById('st-injected-content')
		el.remove()
		window._st = null
	},
	watch: {
		onPhysioPedia(val) {
			if (val) this.createObserver()
			else this.destroyObserver()
		},
		$route() {
			setTimeout(() => this.loading=false, 0)
		},
		loading(v) {
			if (v) setTimeout(()=>this.loading=false, 3000)
		}
	}
}
</script>

<style lang='scss'>

.appNavButton {
	.loading { background: none; }
	.spinner { transform: scale(0.5); margin-right: auto; margin-left: 3px; background: #fff; }
}




html .st-install-43ZVT-g2_ksiLpUX3zSj .st-ui-result .st-ui-type-heading {
	color: $color-primary !important;
	em { color: $color-success !important; background: none !important; }
}

body {
	.st-autocomplete-results {
		> div { 
			border: 1px solid $color-neutral-shadow !important; 
			border-radius: 0 !important;
			margin-top: -2px !important;

			a {
				padding: $size-gutter * 2 !important;
				font-family: 'Cabin';
				&:hover, &.st-keyboard-active-item { background: $color-neutral-silver !important; }
				border-bottom: 1px solid $color-neutral-accent !important;
				border-bottom: none !important;
				span { 
					&:first-child { 
						color: $color-primary-accent !important;
						margin-bottom: 3px !important; 
					}
				}
				.st-ui-type-heading {
					color: $color-primary-accent !important;
				}
				.st-ui-type-detail {

				}
			}
		}
	}
}


.clinicianNav {
	.swapButton svg { width: 20px; }
	.st-default-search-input { 
		font-family: 'Cabin'; 
		font-size: $size-font-standard;
		padding: 0 $size-gutter * 2;
		height: 23px;
		border: 1px solid $color-neutral-shadow !important;
		border-radius: 0;
		border-radius: 12px;
		width: 74px;
		margin-left: auto;
		margin-left: $size-gutter * 2; 
		margin-top: auto;
		margin-bottom: auto;
		background-image: none;	
		/* transition: width 1.5s $ease-out-quart; */
		&:focus {
			width: 478px;
			height: $size-control-height - 4px;
			border-radius: 0;
		}
	}
	.physioPediaWrapper {
		display: flex;
		align-items: center;
		position: relative;
	}
	.referColleagues {
		.label { 
			color: $color-white;
			background: $button-gradient-success;
			display: block;
			height: 24px; 
			padding: 0 $size-gutter * 2;
			line-height: 24px;
			border-radius: 12px;
		}		
		text-decoration: none !important;
	}
}
</style>