<template>
<div class='setBuilderElementsBus' :style='style'>
	<div 
		v-if='bus.length === 1 && el' 
		v-loadEl='el' 
		class='el' 
	/>
	<div 
		v-else 
		class='summary' 
		:style='`grid-template-columns: repeat(${cols}, ${400/6}px);`'
	>
		<template v-for='elementKey in bus'>
			<ExerciseThumbnail 
				v-if='type(elementKey) === "setExercise"' 
				:thumbnailUrl='$store.getters["sets/getThumbnailUrl"](elementKey)'
				:flip='$store.state.sets.elements[elementKey].reflect'
				:key='elementKey' 
				class='thumbnail'
			/>
			<div 
				v-else-if='type(elementKey) === "setDivider"' 
				class='title' 
				:key='elementKey'
			>{{title(elementKey)}}</div>
		</template>
	</div>
	</div>
</template>

<script>
import { elementsMixin } from './elementsMixin'
import ExerciseThumbnail from '@/components/clinician/designer/setBuilder/ExerciseThumbnail'

export default {
	name: 'SetBuilderElementsBus',
	components: {
		ExerciseThumbnail
	},
	mixins: [elementsMixin],
	computed: {
		cols() {
			if (this.bus.find(elementKey => this.type(elementKey) === 'setDivider') && this.bus.length <= 4) return 3
			else return Math.min(this.bus.length, 4)
		},
		style() {
			if (this.bus.length===1) {
				return `transform-origin: ${Math.round(this.originX)}% ${Math.round(this.originY)}%;`
			} else {
				return `transform-origin: top left;`;
			}
		}
	},
	methods: {
		type(elementKey) {
			return this.$store.state.sets.elements[elementKey].type
		},
		title(elementKey) {
			return this.$store.state.sets.elements[elementKey].title
		}
	}
}
</script>

<style lang='scss'>
.setBuilderElementsBus {
	transition: transform 0.4s cubic-bezier(0.22, 1, 0.36, 1), box-shadow 0.4s cubic-bezier(0.76, 0, 0.24, 1);
	transform-origin: top left;

	> .summary {
		display: grid;
		grid-gap: 1px;

		.thumbnail {
			position: relative;
			background: $color-white;
			width: (400px / 6);
			height: (370px / 6);
			
			overflow: hidden;
		}

		.title {
			grid-column: 1/-1;
			line-height: $size-control-height;
			font-size: $size-font-standard;
			font-weight: bold;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.title + .title {
			border-top: 1px dashed $color-neutral-shadow;
		}

		.title:empty {
			height: $size-control-height / 2;
			margin-top: $size-control-height / 2;
			border-top: 1px dashed $color-neutral-shadow;
		}
	}

	> .el {
		
		overflow: hidden;
	}
}

.dropElements .setBuilderElementsBus {
	transform: scale(0.5);
	box-shadow: 0 10px 30px -2px rgba(#000000, 0.35);
}
</style>
