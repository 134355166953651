<template>
<div class='listBus'>
	<div
		v-for='item in (bus.length > max ? bus.slice(0, max) : bus)'
		:key='item.id'
	>{{item.title || item.savename}}</div>
	<div v-if='bus.length > max' class='footer'>{{bus.length - max}} {{$t('elements.parts.more')}}...</div>
</div>
</template>

<script>
export default {
	name: 'ListBus',
	data: () => ({
		max: 20
	}),
	computed: {
		bus() { return this.$store.state.bus.bus }
	}
}
</script>

<style lang='scss'>
.listBus {
	font-size: $size-font-standard;
	line-height: $size-lineHeight-standard;
	white-space: nowrap;

	.footer {
		margin-top: $size-gutter;
		font-weight: bold;
		text-align: right;
	}
}
</style>
