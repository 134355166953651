<template>
<div class='trialButton'>
	<a :href='`https://boarding.simpleset.app/#/register/pro/${$store.state.profile.user.lang}`'>
		<header>{{$t('views.banners.demo.trial.title')}}</header>
		<ul>

			<li>Try SimpleSet FREE for 14 days</li>
			<li>Unlimited Access to SimpleSet's Exclusive Exercise Library</li>
			<li>Unlimited Exercise Programs</li>
			<li>Outcome Measures &amp; Tracking</li>
			<li>Patient Education</li>
			<li>Customize ALL Content</li>
			<li>TeleHealth</li>
			<li>Cancel anytime with no obligation</li>
		</ul>
		<div class='textButton success'>{{$t('views.banners.demo.trial.btnStart')}}</div>
	</a>
</div>
</template>

<script>
export default {
	name: 'TrialButton'
}
</script>

<style lang='scss'>
.trialButton {
	padding: 0 $size-gutter * 2;
	/* outline: 2px dashed $color-primary-accent; */
	cursor: pointer;

	header {
		height: $size-control-height;
		display: flex;
		align-items: center;
		font-size: $size-font-large;
		font-weight: bold;
		margin-bottom: $size-gutter * 2;
		color: $color-primary-accent;
	}

	ul {
		margin: 0;
		padding-left: $size-gutter * 3;
		list-style: none;

		li {
			font-size: $size-font-standard;
			line-height: $size-lineHeight-standard;
			color: $color-white;
			margin: 0;
			padding: 0;
			margin-top: $size-gutter;
			position: relative;
			color: $color-black;

			&:before {
				position: absolute;
				top: -1px;
				left: -$size-gutter * 3;
				color: $color-primary-accent;
				font-weight: bold;
				font-size: 26px;
				content: '•';
			}
		}

	}


	.textButton {
		font-size: $size-font-large;
		height: $size-control-height * 2;
		margin-bottom: $size-gutter * 2;
		margin-top: $size-gutter * 4;
		box-sizing: content-box;
	}

	a { text-decoration: none !important; }
}
</style>
