<template>
<div class='folderSetsBus'>
	<div
		v-for='setKey in (bus.length > max ? bus.slice(0, max) : bus)'
		:key='setKey'
	>{{$store.getters['manageFolderSets/title'](setKey)}}</div>
	<div v-if='bus.length > max' class='footer'>{{bus.length - max}} {{$t('elements.parts.more')}}...</div>
</div>
</template>

<script>
export default {
	name: 'FolderSetsBus',
	data: () => ({
		max: 20
	}),
	computed: {
		bus() { return this.$store.state.bus.bus.keys }
	}
}
</script>

<style lang='scss'>
.folderSetsBus {
	font-size: $size-font-standard;
	line-height: $size-lineHeight-standard;
	white-space: nowrap;

	.footer {
		margin-top: $size-gutter;
		font-weight: bold;
		text-align: right;
	}
}
</style>
