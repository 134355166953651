<template>
<div class='exerciseBrowserElementsBus' :style='style'>
	<div 
		v-if='bus.length === 1 && el' 
		v-loadEl='el' 
		class='el' 
	/>
	<div 
		v-else 
		class='summary' 
		:style='`grid-template-columns: repeat(${Math.min(bus.length, cols)}, 1fr);`'
	>
		<div 
			v-for='elementKey in (bus.length > cols * rows ? bus.slice(0, cols * rows) : bus)' 
			:key='elementKey'
			class='thumbnail'
		>
			<component :is='thumbnailComponent(elementKey)' :elementKey='elementKey' />
		</div>
		<div v-if='bus.length > cols * rows' class='footer'>{{bus.length - cols * rows}} {{$t('elements.parts.more')}}</div>
	</div>
	</div>
</template>

<script>
import { elementsMixin } from './elementsMixin'
import ExerciseThumbnail from '@/components/clinician/designer/exerciseBrowser/ExerciseThumbnail'
import StackThumbnail from '@/components/clinician/designer/exerciseBrowser/StackThumbnail'

export default {
	name: 'ExerciseBrowserElementsBus',
	components: {
		ExerciseThumbnail,
		StackThumbnail
	},
	mixins: [elementsMixin],
	data: () => ({
		cols: 4,
		rows: 4
	}),
	computed: {
		style() {
			if (this.bus.length===1) {
				return `transform-origin: ${Math.round(this.originX)}% ${Math.round(this.originY)}%;`
			} else {
				return `transform-origin: top left;`;
			}
		}
	},
	methods: {
		thumbnailComponent(elementKey) {
			if (this.$store.getters['elements/isExercise'](elementKey)) return 'ExerciseThumbnail'
			else if (this.$store.getters['elements/isStack'](elementKey)) return 'StackThumbnail'
		}
	},
	mounted() {
		document.body.classList.remove('dropElements')
	}
}
</script>

<style lang='scss'>
.exerciseBrowserElementsBus {
	transition: transform 0.4s cubic-bezier(0.22, 1, 0.36, 1), box-shadow 0.4s cubic-bezier(0.76, 0, 0.24, 1);
	transform-origin: top left;

	> .summary {
		display: grid;
		grid-gap: 1px;

		> .thumbnail {
			position: relative;
			box-sizing: content-box;
			background: $color-white;
			width: (400px / 6);
			height: (370px / 6);
			
			overflow: hidden;
		}

		> .footer {
			grid-column: 1/-1;
			line-height: $size-control-height;
			text-align: right;
			color: $color-black;
			font-size: $size-font-standard;
		}
	
		.stackThumbnails.multiple {
			padding: 0;
			grid-gap: 1px;
		}
	}
}
</style>
