<template>
<div class='tips'>
	<div class='closeButtonWrapper'>
		<CloseButton @click='$emit("close")' />
	</div>

	<transition :name='transition'>

		<div class='firstPanel' v-if='step === 1' key='1'>
			<div class='content'><div>
				<header>{{$t('views.tips.panel1.title')}}</header>
				<p>{{$t('views.tips.panel1.p1')}}</p>
				<p>{{$t('views.tips.panel1.p2')}}</p>
				<div class='bigButton' @click='step++'><svg class='smallIcon'><use xlink:href='#angleRight' /></svg></div>
			</div></div>
			<div class='logoWrapper'><div><AnimatedLogo /></div></div>
		</div>

		<div class='panel' v-else-if='step === 2' key='2'>
			<div class='tip'>
				<div class='step'>1</div>
				<div class='text'>{{$t('views.tips.panel2.tip')}}</div>
				<div class='topNext' @click='step++'>{{$t('views.tips.next')}}…</div>
			</div>
			<div class='video'>
				<transition name='fade'>
					<Loading v-if='!vid1Loaded' />
				</transition>
				<div class='videoWrapper' :class='{ loaded: vid1Loaded }'>
					<video autoplay playsinline muted loop src='/mp4/tip1.mp4' @canplay='vid1Loaded = true' crossOrigin='anonymous' />
				</div>
				<div class='prev' @click='step--'><svg class='smallIcon'><use xlink:href='#angleLeft' /></svg></div>
				<div class='next' @click='step++'><svg class='smallIcon'><use xlink:href='#angleRight' /></svg></div>
			</div>
		</div>

		<div class='panel' v-else-if='step === 3' key='3'>
			<div class='tip'>
				<div class='step'>2</div>
				<div class='text'>{{$t('views.tips.panel3.tip')}}</div>
				<div class='topNext' @click='step++'>{{$t('views.tips.next')}}…</div>
			</div>
			<div class='video'>
				<transition name='fade'>
					<Loading v-if='!vid2Loaded' />
				</transition>
				<div class='videoWrapper' :class='{ loaded: vid2Loaded }'>
					<video autoplay playsinline muted loop src='/mp4/tip2.mp4' @canplay='vid2Loaded = true' crossOrigin='anonymous' />
				</div>
				<div class='prev' @click='step--'><svg class='smallIcon'><use xlink:href='#angleLeft' /></svg></div>
				<div class='next' @click='step++'><svg class='smallIcon'><use xlink:href='#angleRight' /></svg></div>
			</div>
		</div>

		<div class='panel' v-else-if='step === 4' key='4'>
			<div class='tip'>
				<div class='step'>3</div>
				<div class='text'>{{$t('views.tips.panel4.tip')}}</div>
				<div class='topNext' @click='step++'>{{$t('views.tips.next')}}…</div>
			</div>
			<div class='video'>
				<transition name='fade'>
					<Loading v-if='!vid3Loaded' />
				</transition>
				<div class='videoWrapper' :class='{ loaded: vid3Loaded }'>
					<video autoplay playsinline muted loop src='/mp4/tip3.mp4' @canplay='vid3Loaded = true' crossOrigin='anonymous' />
				</div>
				<div class='prev' @click='step--'><svg class='smallIcon'><use xlink:href='#angleLeft' /></svg></div>
				<div class='next' @click='step++'><svg class='smallIcon'><use xlink:href='#angleRight' /></svg></div>
			</div>
		</div>

		<div class='lastPanel' v-if='step === 5' key='5'><div>
			<div class='content'><div>
				<header>{{$t('views.tips.panel6.title')}}</header>
				<ul>
					<li>{{$t('views.tips.panel6.li1')}}</li>
					<li>{{$t('views.tips.panel6.li2')}}</li>
					<li>{{$t('views.tips.panel6.li3')}}</li>
					<li>{{$t('views.tips.panel5.tip')}}</li>
					<li>{{$t('views.tips.panel6.li4')}}</li>
				</ul>
				<p>{{$t('views.tips.panel6.p1')}}</p>
				<p>{{$t('views.tips.panel6.p2')}}</p>
			</div></div>
			<div class='logoWrapper'><div><AnimatedLogo /></div></div>
			<div class='prev' @click='step--'><svg class='smallIcon'><use xlink:href='#angleLeft' /></svg></div>
		</div></div>

	</transition>

	<div class='disable' :class='{ disabled }' @click='disabled=!disabled'>
		<CheckButton :checked='disabled' />
		<div class='label'>{{$t('views.tips.disable')}}</div>
	</div>

</div>
</template>

<script>
import CloseButton from '@/components/common/buttons/Close'
import CheckButton from '@/components/common/buttons/Check'
import AnimatedLogo from '@/components/common/AnimatedLogo'
import Loading from '@/components/common/Loading'

export default {
	name: 'Tips',
	components: { CheckButton, CloseButton, AnimatedLogo, Loading },
	data: () => ({
		disabled: false, 
		step: 1, 
		transition: 'rtl',
		vid1Loaded: false,
		vid2Loaded: false,
		vid3Loaded: false,
		vid4Loaded: false
	}),
	watch: {
		step(newStep, oldStep) {
			this.transition = newStep > oldStep ? 'rtl' : 'ltr'
		}
	},
	beforeDestroy() {
		this.$store.commit('profile/setTips', false)		
		if (this.disabled) this.$store.dispatch('profile/save', { showWelcome: false })
	},
	created() {
		this.$store.commit('profile/setTips', true)
	}
}
</script>

<style lang='scss'>
.tips {
	position: relative;
	height: 100%;

	.closeButtonWrapper {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 10;
	}

	.panel, .firstPanel, .lastPanel {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.firstPanel {
		display: flex;

		.logoWrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			min-width: 50%;
			padding-bottom: 30px;
			background: $color-neutral-silver;	

			> div { 
				position: relative; 
				width: 200px; 
				height: 200px; 
				margin: 0 auto; 
				margin-bottom: $size-gutter * 2;
			}
		}

		.content { 
			text-align: center;
			min-width: 50%;
			display: flex;
			align-items: center;
			justify-content: center;	
			padding-bottom: 30px;

			> div {}

			header {
				font-weight: normal;
				font-size: 26px;
				color: $color-primary-accent; 
				margin-bottom: $size-gutter * 7;
				text-align: center;
			}

			p {
				font-size: 20px;
				margin: 0;
				line-height: 32px;
			}

			.bigButton {
				color: $color-white;
				width: 100px;
				height: 100px;
				border-radius: 50%;
				background: $button-gradient-success;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: $size-gutter * 8;
				margin-left: auto;
				margin-right: auto;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;

				svg { width: 60%; height: 60%; }
			}
		}
	}

	.lastPanel {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 $size-gutter * 8;
		padding-bottom: 50px;

		.logoWrapper {
			> div { position: relative; width: 200px; height: 200px; margin: 0 auto; margin-top: $size-gutter * 5; }
		}

		.content {

			header {
				font-weight: normal;
				font-size: 26px;
				color: $color-primary-accent; 
				text-align: center;
			}

			ul { margin: 0; padding: 0; text-align: center; list-style-type: none; margin-bottom: $size-gutter * 2;}

			p, li { font-size: $size-font-large; line-height: $size-lineHeight-large; margin: 0; }

			p { font-weight: bold; color: $color-primary-accent; text-align: center; }

		}

		.prev svg { color: $color-primary-accent; }

	}

	.video {
		padding:  $size-gutter * 4 $size-gutter * 14;
		background: $color-primary;
		overflow: hidden;
		position: relative;
		height: 484px;

		video { 
			width: 100%; 
		}

		.videoWrapper {
			overflow: hidden;
			box-shadow: $box-shadow-lifted-on; 
			opacity: 0;
			background: $color-white; 
			transition: opacity 200ms linear;
			video { margin-bottom: -3px; }
			&.loaded { opacity: 1; }
		}

		.spinner { border: none; }
	}

	.prev, .next {
		position: absolute;
		top: 50%;
		width: 40px;
		height: 50px;
		margin-top: -25px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		svg { color: $color-white; width: 100%; height: 100%; }
	}

	.prev { left: $size-gutter * 4; }

	.next { right: $size-gutter * 4; }

	.tip {
		display: flex;
		align-items: center;
		padding: $size-gutter * 4;
		font-size: $size-font-large;
		line-heiht: $size-lineHeight-large;
		color: $color-black;

		.step {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			display: flex;
			min-width: 40px;
			flex: 0;
			align-items: center;
			justify-content: center;
			color: $color-white;
			font-size: 26px;
			background: $button-gradient-primary;
			border-radius: 30px;
			margin-right: $size-gutter * 2;
		}

		.text {
			flex: 1;
		}

		.topNext {
			height: 40px;
			border-radius: 20px;
			background: $button-gradient-success;
			color: $color-white;
			display: flex;
			align-items: center;
			padding: 0 $size-gutter * 2;
			font-size: $size-font-large;
			margin-right: $size-gutter * 4;
			font-weight: bold;
			cursor: pointer;
		}
	}

	.disable {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 50px;
		z-index: 10;
		display: flex;
		align-items: center;
		justify-content: center;
		background: $color-neutral-accent;
		cursor: pointer;

		&.disabled { background: $color-focus; }

		.checkButton {
			margin-right: $size-gutter * 2;
			pointer-events: none;
		}

		.label { 
			font-size: $size-font-standard; 
		}
	}
}
</style>
