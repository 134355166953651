<template>
<div class='defaultBus'>
	<div 
		v-if='el'
		v-loadEl='el' 
		class='el' 
	/>
</div>
</template>

<script>
import { elementsMixin } from './elementsMixin'

export default {
	name: 'DefaultBus',
	mixins: [elementsMixin]
}
</script>

<style lang='scss'>
.defaultBus { 
	background: $color-white;
}
</style>
