<template>
<div class='demoExpiresIn'>
{{$t('views.banners.demo.lblExpires')}} &nbsp;<b>{{minutes}}:{{seconds}}</b> 
</div>
</template>

<script>
export default {
	name: 'DemoExpiresIn',
	data: function() {
		return {
			interval: null,
			timer: (60 * 5) - Math.floor((Date.now() - this.$store.state.auth.created) / 1000),
			minutes: null,
			seconds: null 
		}
	},
	methods: {
		tick() {
			this.minutes = parseInt(this.timer / 60, 10)
			this.seconds = parseInt(this.timer % 60, 10)
			this.minutes = this.minutes < 10 ? '0' + this.minutes : this.minutes
			this.seconds = this.seconds < 10 ? '0' + this.seconds : this.seconds
			this.timer--
			if (this.timer <= 0) this.$store.dispatch('auth/logout')
		}
	},
	mounted() {
		this.interval = setInterval(this.tick, 1000)
		this.tick()
	},
	beforeDestroy() {
		clearInterval(this.interval)
	}
}
</script>

<style lang='scss'>
.demoExpiresIn {
	font-size: $size-font-standard;
	height: $size-control-height;
	display: flex;
	align-items: center;
	justify-content: center;

	b { font-size: $size-font-large; }
}
</style>
