import { simpleState } from '@/store/helpers'

export const elementsMixin = {
	props: ['bus', 'originX', 'originY'],
	directives: {
		loadEl: {
			bind: function(el, binding) {
				el.appendChild(binding.value)
			}
		}
	},
	data: () => ({
		el: null
	}),
	mounted() {
		if (this.bus && simpleState.busEl) this.el = simpleState.busEl
	}
}