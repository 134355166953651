<template>
<div class='demoFunnel'><Scroll><div class='demoWrapper'>
	<div class='topPanel'>
		<div class='demoWelcome'>
			<header>{{$t('views.banners.demo.title')}}</header>
			<p>{{$t('views.banners.demo.p1')}}</p>
			<p class='last'>{{$t('views.banners.demo.p2')}}</p>
		</div>
		<TrialButton />
	</div>
	<ExpiresIn />
</div></Scroll></div>
</template>

<script>
import TrialButton from './TrialButton'
import ExpiresIn from './ExpiresIn'
import Scroll from '@/components/common/Scroll'

export default {
	name: 'DemoFunnel',
	components: { TrialButton, ExpiresIn, Scroll }
}
</script>

<style lang='scss'>
.demoFunnel {
	width: 240px;
	height: 100%;
	background: $color-white;

	.demoWrapper {
		padding-left: $size-gutter * 2;
		padding-right: $size-gutter * 2;
		padding-bottom: $size-gutter;
		padding-top: $size-gutter;
		display: grid;
		grid-template-rows: 1fr auto;
	}

	.topPanel .demoWelcome {
		padding: $size-gutter * 2;

		header {
			height: $size-control-height;
			display: flex;
			align-items: center;
			font-size: $size-font-large;
			font-weight: bold;
			margin-bottom: $size-gutter * 2;
			color: $color-primary-accent;
		}

		p {
			font-size: $size-font-standard;
			line-height: $size-lineHeight-standard;
			margin: 0;

			&.last { padding-top: $size-gutter * 3; }
		}
	}
}
</style>
