<template>
<div v-if='showReqs' class='mobileReqs'>
	<header>Portrait not supported.  Please rotate to Landscape.</header>
</div>
</template>

<script>
export default {
	name: 'MobileReqs',
	data() { return {
		showReqs: window.innerHeight > window.innerWidth
	}},
	methods: {
		onChange() {
			this.showReqs = window.orientation === 0 || window.orientation === 180
		}
	},
	mounted() {
		window.addEventListener('orientationchange', this.onChange)
		this.onChange()
	},
	beforeDestroy() {
		window.removeEventListener('orientationchange', this.onChange)
	}
}
</script>

<style lang='scss'>
.mobileReqs {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99999;
	background: rgba(#000, 0.9);
	display: flex;
	align-items: center;
	justify-content: center;

	header { 
		color: $color-white;
		font-size: $size-font-large;
		font-weight: bold;
		text-shadow: 0 0 40px rgba(#000, 0.8);
	}
}
</style>