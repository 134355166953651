<template>
<div class='bulkParametersBus'>
	<div 
		v-if='el'
		v-loadEl='el' 
		class='el' 
	/>
</div>
</template>

<script>
import { elementsMixin } from './elementsMixin'

export default {
	name: 'BulkParametersBus',
	mixins: [elementsMixin]
}
</script>

<style lang='scss'>
.bulkParametersBus { 
	padding: $size-gutter * 2;
}
</style>
