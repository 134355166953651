<template>
<div class='bus' :style='style'><div :class='{ padded: !startX }'>
	<component :is='passengerComponent' :bus='$store.state.bus.bus' :originX='originX' :originY='originY' />
</div></div>
</template>

<script>
import { eventBus } from '@/utilities/eventBus'
import { upperFirst } from 'lodash'
import { simpleState } from '@/store/helpers'
import BrowserElements from './passengers/BrowserElements'
import SetElements from './passengers/SetElements'
import FolderSets from './passengers/FolderSets'
import List from './passengers/List'
import BulkParameters from './passengers/BulkParameters'
import DefaultPassengers from './passengers/Default'

export default {
	name: 'Bus',
	components: {
		BrowserElements,
		SetElements,
		FolderSets,
		BulkParameters,
		DefaultPassengers,
		List
	},
	data: () => ({
		x: 0,
		y: 0,
		offsetX: null,
		offsetY: null,
		startX: null,
		startY: null,
		screenX: null,
		screenY: null,
		originX: null,
		originY: null,
		elFromPoint: null
	}),
	computed: {
		style() {
			if (this.startX) {
				const x = this.x - this.screenX
				const y = this.y - this.screenY
				return `top: ${this.startY}px; left: ${this.startX}px; transform: translate(${x}px, ${y}px)`				
			} else {
				const x = this.x - this.offsetX + 7
				const y = this.y - this.offsetY + 7				
				return `transform: translate(${x}px, ${y}px);`				
			}
		},
		passengerComponent() { 
			const name = upperFirst(this.$store.state.bus.contentType)
			return this.$options.components[name] ? name : 'DefaultPassengers'
		}
	},
	methods: {
		addListeners: function() {
			//document.addEventListener('touchmove', this.onMove)
			//document.addEventListener('touchend', this.onEnd)
			//document.addEventListener('mousemove', this.onMove)
			//document.addEventListener('mouseup', this.onEnd)
			/*
			you can't use pointer enter events here because ios safari breaks them if there is an overscroll happening

			*/
			if (this.$store.state.main.mobile) {			
				document.addEventListener('touchmove', this.onMove, { passive: true })
				document.addEventListener('touchstart', this.onEnd, { passive: true }) // in case a second finger touches
				document.addEventListener('touchend', this.onEnd)
			} else {
				document.addEventListener('pointermove', this.onMove)
				document.addEventListener('pointerup', this.onEnd)				
			}
		},
		removeListeners: function() {
			//document.removeEventListener('touchmove', this.onMove)
			//document.removeEventListener('touchend', this.onEnd)		
			//document.removeEventListener('mousemove', this.onMove)
			//document.removeEventListener('mouseup', this.onEnd)					
			if (this.$store.state.main.mobile) {			
				document.removeEventListener('touchmove', this.onMove)
				document.removeEventListener('touchend', this.onEnd)	
				document.removeEventListener('touchstart', this.onEnd)	
			} else {
				document.removeEventListener('pointermove', this.onMove)
				document.removeEventListener('pointerup', this.onEnd)					
			}			
		},
		onMove: function(e) {
			if (e) {
				const pageX = e.pageX ? e.pageX : e.touches ? e.touches[0].pageX : 0
				const pageY = e.pageY ? e.pageY : e.touches ? e.touches[0].pageY : 0
				const clientX = e.clientX ? e.clientX : e.touches ? e.touches[0].clientX : 0
				const clientY = e.clientY ? e.clientY : e.touches ? e.touches[0].clientY : 0
				const el = document.elementFromPoint(clientX, clientY)
				if (el) {
					this.elFromPoint = el
					eventBus.$emit('bussing', el)
				}				
				this.x = pageX - 1
				this.y = pageY - 1
			}
		},		
		onEnd() {
			eventBus.$emit('unloadBus', this.elFromPoint)	
			this.unloadBus()
			this.removeListeners()
		},
		unloadBus() {
			this.$store.dispatch('bus/unloadBus')
		}
	},
	mounted() {
		if (simpleState.startX) {
			this.startX = simpleState.startX 
			this.startY = simpleState.startY 
			this.screenX = simpleState.screenX 
			this.screenY = simpleState.screenY
			this.originX = simpleState.originX
			this.originY = simpleState.originY
		} else {
			const box = this.$el.parentNode.getBoundingClientRect()
			this.offsetX = box.x
			this.offsetY = box.y
		}
		this.x = simpleState.screenX - 1
		this.y = simpleState.screenY - 1
		this.addListeners()
	},
	beforeDestroy() {
		this.removeListeners()
	}
}
</script>

<style lang='scss'>
.bus {
	pointer-events: none;
	position: absolute;
	z-index: 9999;
	animation: fadeIn 200ms linear;

	> div {
		/* animation: bus 1s; */
		/* box-shadow: $box-shadow-lifted-on; */
		opacity: 0.98;    
		> div { 
			border: 1px solid $color-neutral-shadow; 
			box-shadow: $box-shadow-lifted-on;
		}

		&.padded {
			border-radius: 2px;
			> div { 
				padding: $size-gutter * 2; 
				border-radius: 2px;
				background: #fff;
			}
		}
	}

	.listBus, .folderSetsBus, .bulkParametersBus {
		background: $button-gradient-success !important;
		color: #fff;
		border: none;
		box-shadow: none;
		border-radius: 2px;		
	}
}

.mobile .bus > div {
	animation: bus 600ms;
    transform: scale3d(1.05, 1.05, 1.05);
}

@keyframes bus {
  0% {
    opacity: .98;
    transform: scale3d(1, 1, 1);
    -webkit-animation-timing-function: $ease-out-quart;
  }
  to {
    opacity: .98;
    transform: scale3d(1.05, 1.05, 1.05);
  }
}
</style>